import React from "react";
// import Axios from 'axios';
// import baseUrl from './config/baseUrl';
import Main from "./components/Main";
import './assets/css/style.css';
// import { BrowserRouter } from "react-router-dom";

function App() {
  return <Main />;
}

export default App;
