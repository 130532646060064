import React, { Component } from "react";
import axios from "axios";
import baseUrl from "../config/baseUrl";
import Home from "./subComponents/Home";
import Swiper from "react-id-swiper";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
// import ReactPlayer from "react-player";
import MusicPlayer from "react-responsive-music-player";
import Loader from "../loader.gif";
import Twich from '../Twich.png';
import liveradio from "../LiveRadio.png";
import liveradio1 from "../LiveRadio1.png";
import DJX from "../DJX.png";
import moment from "moment";
import person from '../assets/images/background.png';
import { store } from "react-notifications-component";
import mainLogo from '../assets/images/200X200.png';

const format = "hh:mm A";
const $ = window.$;
class Main extends Component {
  state = {
    image: [],
    boi: [],
    music_catagary: [],
    music_catagary_id: 13,
    song_list: [],
    video: [],
    playlist: [],
    radio: "",
    event: [],
    Sponsored: [],
    radioplay: true,
    socialmedia: {
      facebook: "",
      instagram: "",
      twitter: "",
      soundcloud: "",
      youtube: "",
    },
    booking: {
      fullname: "",
      phone_number: "",
      email: "",
      booking_date: "",
      booking_time: "12:00 AM",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      type: "Night Club",
    },
    privacy: "",
    terms: "",
    mixes: {
      user_id: "30",
      title: "",
      description: "",
    },
    other: false,
    playMusic: "",
    musicPlay: "",
  };
  // componentWillMount(){
  //   console.log('sdgdsuhkhskjhdskh');
  // }
  // componentDidUpdate(){
  //   console.log('ashjih');
  // }
  componentDidMount() {
    var url = baseUrl + "gallery";
    // ============== gallary ============
    axios
      .get(url)
      .then((res) => {
        // debugger
        this.setState({
          image: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // ================= BOI  ======================
    axios
      .get(baseUrl + "biography")
      .then((res) => {
        // debugger
        this.setState({
          boi: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    //============ Catagary ======================
    axios
      .get(baseUrl + "music_category")
      .then((res) => {
        // console.log(res,res.data.data[0].category_id)
        this.setState({
          music_catagary: res.data.data,
          music_catagary_id:res.data.data[0].category_id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // =============== Song List ================
    axios
      .post(baseUrl + "song", { id: this.state.music_catagary_id })
      .then((res) => {
        this.setState({
          song_list: res.data.data,
          playlist: res.data.data.map((x) => {
            return {
              url: x.song_with_path,
              cover: x.image_with_path,
              title: x.song_name,
              artist: ["DJ X MIXES"],
            };
          }),
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // ============ Video ==============
    axios
      .get(baseUrl + "video")
      .then((res) => {
        this.setState({
          video: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // ========= Radio ===============
    axios
      .get(baseUrl + "radio")
      .then((res) => {
        // console.log(res.data.data[0].radio_link)
        this.setState({
          radio: res.data.data[0],
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // ================== Events =================
    axios
      .get(baseUrl + "event")
      .then((res) => {
        this.setState({
          event: res.data.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
    // ================ Sponsored ===============
    axios
      .get(baseUrl + "sponsor")
      .then((res) => {
        this.setState({
          Sponsored: res.data.data,
        });
      })
      .catch((error) => {
        console.log("Error", error);
      });
    // ============== socialmedia =============
    axios
      .get(baseUrl + "socialmedia")
      .then((res) => {
        this.setState({
          socialmedia: {
            ...this.state.socialmedia,
            facebook: res.data.data[0].social_link,
            instagram: res.data.data[1].social_link,
            youtube: res.data.data[2].social_link,
            twitter: res.data.data[3].social_link,
            soundcloud: res.data.data[4].social_link,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // ====================== Privacy =============
    axios
      .get(baseUrl + "privacy")
      .then((res) => {
        // console.log(res.data.data);
        this.setState({
          privacy: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // ====================== tearm and condition =============
    axios
      .get(baseUrl + "terms")
      .then((res) => {
        // console.log(res.data.data);
        this.setState({
          terms: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    //   $("#musicplayer").click((e)=> {
    //     $(".controls .fa-pause").click(e=>{
    //       if(this.state.playMusic !== "1"){
    //         this.setState({
    //           musicPlay:this.state.playMusic,
    //         })
    //       }else{
    //         this.setState({
    //           playMusic:"1"
    //         })
    //       }
    //     })
    //     $(".controls .fa-play").click(e=>{
    //       if(this.state.musicPlay){
    //         this.setState({
    //           playMusic:this.state.musicPlay
    //         })
    //       }
    //     })
    //   console.log(this.state.playMusic)
    // });
  }
  catagarynav = (e, id) => {
    e.preventDefault();
    this.setState(
      {
        song_list: "",
      },
      () => {
        axios
          .post(baseUrl + "song", { id: id })
          .then((res) => {
            this.setState({
              music_catagary_id: id,
              song_list: res.data.data,
              playlist: res.data.data.map((x) => {
                return {
                  url: x.song_with_path,
                  cover: x.image_with_path,
                  title: x.song_name,
                  artist: ["DJ X MIXES"],
                };
              }),
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };
  changeInput = (e) => {
    if (e.target.name === "type" && e.target.type === "radio") {
      this.setState({
        other: false,
      });
    }
    this.setState({
      booking: {
        ...this.state.booking,
        [e.target.name]: e.target.value,
      },
    });
  };
  changeInputMixes = (e) => {
    this.setState({
      mixes: {
        ...this.state.mixes,
        [e.target.name]: e.target.value,
      },
    });
  };
  changeInputTime = (e) => {
    if (e) {
      this.setState({
        booking: {
          ...this.state.booking,
          booking_time: e.format(format),
        },
      });
    } else {
      this.setState({
        booking: {
          ...this.state.booking,
          booking_time: "",
        },
      });
    }
  };
  submitMixes = (e) => {
    e.preventDefault();
    var { title, description } = this.state.mixes;
    if (title && description) {
      axios
        .post(baseUrl + "rquestmix", this.state.mixes)
        .then((res) => {
          // console.log(res);
          if (res.data.status === 1) {
            store.addNotification({
              title: "DJX Mixes Success",
              message: "Your Request Accepted",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      store.addNotification({
        title: "Worning",
        message: "Please Fill All Details",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
          pauseOnHover: true,
          showIcon: true,
        },
      });
    }
  };
  submitBookingData = (e) => {
    e.preventDefault();
    const {
      fullname,
      email,
      booking_date,
      booking_time,
      address,
      city,
      state,
      zipcode,
      country,
      type,
    } = this.state.booking;
    // console.log(this.state.booking)
    if (
      fullname &&
      email &&
      booking_date &&
      booking_time &&
      address &&
      city &&
      state &&
      zipcode &&
      country &&
      type
    ) {
      axios
        .post(baseUrl + "booking", this.state.booking)
        .then((res) => {
          if (res.data.status === 1) {
            store.addNotification({
              title: "DJX Booking Success",
              message: "Your Request Accepted",
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // alert('Fill All Data');
    } else {
      store.addNotification({
        title: "Worning",
        message: "Please Fill All Details",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
          pauseOnHover: true,
          showIcon: true,
        },
      });
    }
    // console.log(this.state)
  };
  playSongRequest = (e) => {
    var playsong = $(".modal-body .player audio");
    var musicname = $(".player-control .music-info .title")[0];
    var selectmusicname = $(e.target).closest("div").children("h5")[0];
    // console.log(musicname, selectmusicname);
    this.setState({
      playMusic: selectmusicname.innerText,
    });
    if (musicname.innerText !== selectmusicname.innerText) {
      musicname.innerText = selectmusicname.innerText;
      playsong.attr("src", $(e.target).closest("div").children("a")[0].name);
    }
    if (
      $(".modal-body .player audio")[0].paused === false &&
      musicname.innerText === selectmusicname.innerText
    ) {
      $(".controls .fa-pause").trigger("click");
      // playsong.trigger("pause");
      this.setState({
        playMusic: "1",
      });
    } else {
      $(".controls .fa-play").trigger("click");
    }
  };
  render() {
    const live = {
      slidesPerView: "auto",
      spaceBetween: 10,
      grabCursor: true,
      loop: true,
      centeredSlides: true,
    };
    const navigation={
      slidesPerView: 4,
      spaceBetween: 30,
      centeredSlides: true,
      
    }
    const params = {
      // effect: "coverflow",
      // coverflowEffect: {
      //   rotate: 100,
      //   stretch: 0,
      //   depth: 100,
      //   modifier: 1,
      //   slideshow: "true",
      // },
      slidesPerView: "auto",
      spaceBetween: 40,
      centeredSlides: true,
      grabCursor: true,
      // loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    // ======================== Gallaery ===========
    const gallaryLi = this.state.image.length
      ? this.state.image.map((x, i) => {
          return <li key={i} data-target={x.id} data-slide-to={x.id}></li>;
        })
      : null;
    const gallary = this.state.image.length ? (
      this.state.image.map((x, i) => {
        return (
          <div
            key={i}
            className={i === 0 ? "carousel-item active" : "carousel-item"}
          >
            <img
              className="gallary-img img-fluid"
              src={x.image_with_path}
              alt="First slide"
            />
          </div>
        );
      })
    ) : (
      <div className="col-12 text-center">
        <img src={Loader} />
      </div>
    );
    // ================ Song ====================
    const songlist = this.state.song_list.length ? (
      this.state.song_list.map((x, i) => {
        return (
          <div key={i} className="swiper-slide py-5">
            <img src={x.image_with_path} alt="" />
            <div className="info mb-2">
              <h5 className="px-2 Song-name">{x.song_name}</h5>
              <p className="px-2">{x.Song_description}</p>
              <a
                onClick={this.playSongRequest}
                name={x.song_with_path}
                data-toggle="modal"
                data-target="#exampleModal1"
              >
                {this.state.playMusic === x.song_name ? (
                  <i className="fa fa-pause djx" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-play djx" aria-hidden="true"></i>
                )}
              </a>
              <a
                className="float-right"
                style={{ marginTop: "-30px", border: "none" }}
              >
                {x.song_duration}
              </a>
              {/* <a href={x.song_with_path} className="px-2">
                  Play
                </a> */}
            </div>
          </div>
        );
      })
    ) : (
      <div className="swiper-slide py-5">
        <img src={Loader} />
      </div>
    );

    // ================= Video  ============
    const videolist = this.state.video.length
      ? this.state.video.map((x, i) => {
          return (
            <div
              key={i}
              id="swiper-slide-video1"
              className="swiper-slide  py-5 mb-5"
            >
              <img src={x.image_with_path} alt="" />
              <div className="info">
                <h5>{x.video_name} </h5>
                <a href={x.video_url} target="_blank">
                  <i
                    className="fa fa-youtube-play text-danger"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          );
        })
      : null;
    // ==================== Events ====================
    const event = this.state.event.length
      ? this.state.event.map((x, i) => {
          return (
            <div key={i} id="wrapper" className="swiper-slide mx-5">
              <div className="clash-card barbarian py-5">
                <img
                  className="img-fluid"
                  src={x.image_with_path}
                  alt="barbarian"
                />
                <div className="clash-card__unit-name">{x.event_name}</div>
                <div className="clash-card__level clash-card__level--barbarian">
                  {x.event_address} <br />
                  {x.event_date} at {x.event_time}
                </div>
                <div className="clash-card__unit-description">
                  {x.event_description}
                </div>
              </div>
            </div>
          );
        })
      : null;
    // ===================== Sponsored ======================
    const Sponsored = this.state.Sponsored.length
      ? this.state.Sponsored.map((x, i) => {
          return (
            <div key={i} id="wrapper" className="swiper-slide mx-5">
              <div className="clash-card barbarian py-5">
                <img
                  className="img-fluid"
                  src={x.image_with_path}
                  alt="barbarian"
                />
                <div className="clash-card__unit-name">{x.sponsor_name}</div>
                <div className="clash-card__level clash-card__level--barbarian px-2">
                  {x.sponsor_address}
                </div>
                <div className="clash-card__unit-description">
                  {x.sponsor_description}
                </div>
              </div>
            </div>
          );
        })
      : null;
    return (
      <>
        <Home />
        <section
          id="music"
          style={{ backgroundImage: "url('./assets/images/parallax3.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className="display-5 text-white mt-5 text-uppercase">
                  Music
                </p>
                <nav
                  id="catagarynav"
                  className="navbar  navbar-expand-lg navbar-light"
                >
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav1"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <img src="./assets/images/icons8-menu-501.png" alt="" />
                  </button>
                  <div className="collapse navbar-collapse" id="navbarNav1">
                    <ul className="navbar-nav mx-auto mt-5">
                  
                      {this.state.music_catagary.length
                        ? this.state.music_catagary.map((x, i) => {
                        
                            return (
                              <li key={i} className="nav-item">
                                <a
                                  id={x.category_id}
                                  className={
                                    x.category_id ===
                                    this.state.music_catagary_id
                                      ? "nav-link qwsmoothscroll text-nowrap active"
                                      : "nav-link qwsmoothscroll text-nowrap"
                                  }
                                  onClick={(e) => {
                                    this.catagarynav(e, x.category_id);
                                  }}
                                >
                                  {x.category_name}
                                </a>
                              </li>
                            );
                          })
                        : null}
                              
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="col-12 pb-5">
                {this.state.song_list.length ? (
                  <Swiper {...params}>{songlist}</Swiper>
                ) : (
                  <div className="col-12 text-center">
                    <p className="djx-text">Song not Available</p>
                  </div>
                )}
                {/* <button onClick={goPrev}>Prev</button>
      <button onClick={goNext}>Next</button> */}
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal fade"
          id="exampleModal1"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <img className="float-left" src={DJX} />
                <h3
                  className="modal-title mx-sm-auto pl-2 pl-sm-5"
                  id="exampleModalLabel"
                >
                  DJX Music
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" id="musicplayer">
                {this.state.playlist.length ? (
                  <MusicPlayer mode="vertical" playlist={this.state.playlist} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* <div id="modal1" className="modal bottom-sheet">
          <div className="modal-content">
            <iframe id="modalframe" className="qt-modalframe" src="#"></iframe>
          </div>
        </div> */}
        <section
          id="photos"
          style={{ backgroundImage: "url('./assets/images/parallax3.jpg')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className="display-5 text-white my-5 text-uppercase">
                  Gallery
                </p>
              </div>
              <div className="col-12">
                <div
                  id="carouselExampleIndicators1"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">{gallaryLi}</ol>
                  <div className="carousel-inner">{gallary}</div>
                  <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators1"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators1"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="bio"
          style={{
            backgroundImage: "url('./assets/images/bio.jpg')",
            backgroundSize: " cover",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className="display-5 text-white mt-5 text-uppercase">
                  biography
                </p>
              </div>
              <div className="col-sm-10 col-12 mx-auto">
                <div className="card text-white background-transfarent">
                  <div className="card-body p-md-5">
                    {/* <h2 className="border-bottom border-danger">CAREER</h2> */}
                    <p className="text-justify mt-4 fontsize-22">
                      {this.state.boi.biography_description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="event">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className="display-5 text-white mt-5 text-uppercase">
                  events
                </p>
              </div>

              <div className="col-12">
                {this.state.event.length ? (
                  <Swiper {...live}>{event}</Swiper>
                ) : null}
              </div>
            </div>
          </div>
        </section>
        <section
          id="booking"
          style={{
            backgroundImage: "url('./assets/images/parallax3.jpg')",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className="display-5 text-white my-5 text-uppercase">
                  Book djx
                </p>
              </div>
              <div className="col-md-8 col-11 mx-auto">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-6 pr-md-1">
                          <div className="form-group">
                            <label>Full Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Full Name"
                              name="fullname"
                              onChange={this.changeInput}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 pl-md-1">
                          <div className="form-group">
                            <label>Phone Number</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Phone Number"
                              name="phone_number"
                              onChange={this.changeInput}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email Address"
                              name="email"
                              onChange={this.changeInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pr-md-1">
                          <div className="form-group">
                            <label>Date</label>
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Date"
                              name="booking_date"
                              onChange={this.changeInput}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 pl-md-1">
                          <div className="form-group">
                            <label>Time</label>
                            <TimePicker
                              showSecond={false}
                              className="form-control"
                              name="booking_time"
                              onChange={this.changeInputTime}
                              format={format}
                              use12Hours
                              inputReadOnly
                              defaultValue={moment("12:00 a", format)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Address</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              name="address"
                              onChange={this.changeInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pr-md-1">
                          <div className="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              onChange={this.changeInput}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 pl-md-1">
                          <div className="form-group">
                            <label>State</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Country"
                              name="state"
                              onChange={this.changeInput}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 pr-md-1">
                          <div className="form-group">
                            <label>Zip Code</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="ZIP Code"
                              name="zipcode"
                              onChange={this.changeInput}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 pl-md-1">
                          <div className="form-group">
                            <label>Country</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Country"
                              name="country"
                              onChange={this.changeInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 my-2">
                          <div className="radio-toolbar ml-0">
                            <input
                              type="radio"
                              name="type"
                              id="djtype1"
                              value="Night Club"
                              defaultChecked
                              onClick={this.changeInput}
                            />
                            <label htmlFor="djtype1">Night Club</label>
                          </div>
                        </div>
                        <div className="col-md-6 my-2">
                          <div className="radio-toolbar ml-0">
                            <input
                              type="radio"
                              name="type"
                              id="djtype2"
                              value="Lounge"
                              onClick={this.changeInput}
                            />
                            <label htmlFor="djtype2">Lounge</label>
                          </div>
                        </div>
                        <div className="col-md-6 my-2">
                          <div className="radio-toolbar ml-0">
                            <input
                              type="radio"
                              name="type"
                              id="djtype3"
                              value="Festival"
                              onClick={this.changeInput}
                            />
                            <label htmlFor="djtype3">Festival</label>
                          </div>
                        </div>
                        <div className="col-md-6 my-2">
                          <div className="radio-toolbar ml-0">
                            <input
                              type="radio"
                              name="type"
                              id="djtype4"
                              value="Private Function"
                              onClick={this.changeInput}
                            />
                            <label htmlFor="djtype4">Private Function</label>
                          </div>
                        </div>
                        <div className="col-md-6 my-2">
                          <div className="radio-toolbar ml-0">
                            <input
                              type="radio"
                              name="type"
                              id="djtype5"
                              value="Other"
                              onClick={(e) => {
                                this.setState({
                                  other: !this.state.other,
                                  booking: { ...this.state.booking, type: "" },
                                });
                              }}
                            />
                            <label htmlFor="djtype5">Other</label>
                          </div>
                        </div>
                        <div className="col-md-6 my-2">
                          {this.state.other ? (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Other"
                              name="type"
                              onChange={this.changeInput}
                            />
                          ) : null}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn button-4 btn-lg"
                            name="upbooking_date"
                            value="submit"
                            onClick={this.submitBookingData}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="video"
          className="my-5"
          style={{
            backgroundImage: "url('./assets/images/galaxy-2.jpg')",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className="display-5 text-white pt-3 text-uppercase">
                  djx Videos
                </p>
              </div>
              <div className="col-12">
                {this.state.video.length ? (
                  <Swiper {...params}>{videolist}</Swiper>
                ) : null}
                {/* <ReactPlayer
                  url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                  playing
                /> */}
              </div>
            </div>
          </div>
        </section>
        <section id="event">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className="display-5 text-white mt-5 text-uppercase">
                  Sponsored By
                </p>
              </div>

              <div className="col-12">
                {this.state.Sponsored.length ? (
                  <Swiper {...live}>{Sponsored}</Swiper>
                ) : null}
              </div>
            </div>
          </div>
        </section>
        <section
          id="footer"
          style={{
            backgroundImage: "url('./assets/images/galaxy-3.jpg')",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="kNNzPp mx-auto">
              <img
                className="img-fluid"
                width="200px"
                src={mainLogo}
                alt=""
              />
              <span className="maxwidth1 text-capitalize text-center text-white">
                <span
                  className="mx-2 text-white request-css"
                  data-toggle="modal"
                  data-target="#privacypolicy"
                >
                  Privacy Policy
                </span>{" "}
                |
                <span
                  className="mx-2 text-white request-css"
                  data-toggle="modal"
                  data-target="#Termsandcondition"
                >
                  Terms and condition
                </span>{" "}
                |
                <span
                  className="mx-2 text-white request-css"
                  data-toggle="modal"
                  data-target="#RequestMixes"
                >
                  Request Mixes
                </span>
              </span>
              <span
                className="maxwidth1 text-capitalize text-center"
                style={{ color: "#fdf0ed" }}
              >
                COPYRIGHT 2020 BY DJX EL AUTENTICO.
              </span>
              <div className="row my-4">
                <div className="col-12 text-danger text-center footer-icon text-right">
                  <a
                    href={this.state.socialmedia.twitter}
                    target="_blank"
                    className="footer-link text-danger"
                  >
                    <i className=" my-3 fa fa-twitter fa-2x   social-media mx-1"></i>
                  </a>
                  <a
                    href={this.state.socialmedia.instagram}
                    target="_blank"
                    className="footer-link text-danger"
                  >
                    <i className=" my-3 fa fa-instagram fa-2x   social-media mx-1  "></i>
                  </a>
                  <a
                    href={this.state.socialmedia.facebook}
                    target="_blank"
                    className="footer-link text-danger"
                  >
                    <i className=" my-3 fa fa-facebook-square fa-2x   social-media mx-1 "></i>
                  </a>
                  <a
                    href={this.state.socialmedia.soundcloud}
                    target="_blank"
                    className="footer-link text-danger"
                  >
                    <i className=" my-3 fa fa-soundcloud fa-2x   social-media mx-1 "></i>
                  </a>
                
                  <a
                    href="https://www.twitch.tv/djxmiami"
                    target="_blank"
                    className="footer-link text-danger"
                  >
                    <img className="social-media-img" src={Twich} alt="twitch"/>
                  </a>
                </div>
              </div>
             {/* { this.state.radio ?( */}
              <button
                id="fixedbutton"
                onClick={(e) => {
                  this.setState({ radioplay: !this.state.radioplay });
                }}
                draggable
              >
                {this.state.radioplay ? (
                  <img src={liveradio} alt="Radio" />
                ) : (
                  <img src={liveradio1} alt="Radio" />
                )}

                <audio
                  id="liveradio"
                  className="d-none glyphicon-play"
                  controls
                >
                  {
                    this.state.radio ? (<source
                    src={this.state.radio.radio_link}
                    type="audio/mpeg"
                  ></source>) : null
                  }
                  
                  
                </audio>
              </button>
             {/* ):null} */}
              
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          id="privacypolicy"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3
                  className="modal-title mx-sm-auto pl-2 pl-sm-5"
                  id="exampleModalLabel"
                >
                  Privacy Policy
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" id="musicplayer">
                <p className="text-justify">
                  {this.state.privacy.privacy_description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Termsandcondition"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3
                  className="modal-title mx-sm-auto pl-2 pl-sm-5"
                  id="exampleModalLabel"
                >
                  Terms and condition
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" id="musicplayer">
                <p className=" text-justify px-2">
                  {this.state.terms.teams_description}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="RequestMixes"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3
                  className="modal-title mx-sm-auto pl-2 pl-sm-5"
                  id="exampleModalLabel"
                >
                  Request Mixes
                </h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" id="musicplayer">
                <div className="row">
                  <div className="col-12">
                    <form>
                      <div className="row">
                        <div className="col-10 mx-auto">
                          <div className="form-group">
                            <label>Title</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              onChange={this.changeInputMixes}
                            />
                          </div>
                        </div>
                        <div className="col-10 mx-auto">
                          <div className="form-group">
                            <label>Description</label>
                            <textarea
                              type="text"
                              rows="3"
                              className="form-control"
                              placeholder="Description"
                              name="description"
                              onChange={this.changeInputMixes}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12 text-center">
                          <button
                            className="btn btn-success"
                            onClick={this.submitMixes}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="djx-img">
          <img
            className="img-fluid"
            src={person}
            alt=""
          />
        </div> */}
      </>
    );
  }
}
export default Main;
