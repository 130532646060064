import React, { Component } from "react";
import '../../assets/css/music.css';
import mainbg from '../../assets/images/main-bg.jpg';
import mainLogo from '../../assets/images/200X200.png';
import playStore from '../../assets/images/p.png';
import appStore from '../../assets/images/a.png';
import person from '../../assets/images/background.png';
const $ = window.$;

class Home extends Component {
  componentDidMount() {
    
    // $(window).on("load", function () {

    //  var preloaderFadeOutTime = 500;
    
    //   function hidePreloader() {
    //     var preloader = $('.spinner-wrapper');
    //     preloader.fadeOut(preloaderFadeOutTime);
    //   }
    //   hidePreloader();
    // });
  }
  
  render() {
    return (
      <>
      {/* <div class="spinner-wrapper">
        <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
        </div>
    </div> */}

      <section
        id="header"
        className="hSACzk"
        style={{ backgroundImage: "url(" + mainbg + ")" }}
      >
        <header id="home">
          <div className="container px-0">
            {/* <div className="row">
              <div className="col-12 text-center">
                <img
                  style={{ width: "200px" }}
                  className="float-left"
                  src="./assets/images/DJX SITE FRONT PIC.png"
                  alt=""
                />
              </div>
            </div> */}
            <div>
              <div className="gGcadR">
                <div className="kNNzPp mx-auto">
              
                <img
                  src={mainLogo}
                  className="float-right"
                  alt=""
                />
                  <span
                    className="boluTB maxwidth1 display-5 text-capitalize"
                    style={{ color: "#fdf0ed" }}
                  >
                    EL AUTENTICO
                    <br />
                    <div className="row">
                      <div className="col-12 text-center">
                        <a href="https://apps.apple.com/in/app/djx/id1515554563" target="_blank">
                          <img
                            style={{ width: "250px" }}
                            className="img-fluid"
                            src={appStore}
                            alt=""
                          />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.rn_djx" target="_blank">
                          <img
                            style={{ width: "250px" }}
                            className="img-fluid"
                            src={playStore}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    {/* <button
                        className="btn sendmsg mt-5"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        Get The App !
                      </button> */}
                  </span>

                  {/* <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">
                              Get The App !
                            </h3>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-12 text-center">
                                <a href="#">
                                  <img
                                    style={{ width: "250px" }}
                                    className="img-fluid"
                                    src="./assets/images/a.png"
                                    alt=""
                                  />
                                </a>
                                <a href="#">
                                  <img
                                    style={{ width: "250px" }}
                                    className="img-fluid"
                                    src="./assets/images/p.png"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </header>
        <div id="menu" className="qt-menu-wrapper">
          <nav className="navbar navbarfixed navbar-expand-lg navbar-light">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src="./assets/images/icons8-menu-50.png" alt="" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mx-auto mt-5">
                <li className="nav-item">
                  <a
                    id="homenav"
                    className="nav-link qwsmoothscroll active"
                    href="#home"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="musicnav"
                    className="nav-link qwsmoothscroll"
                    href="#music"
                  >
                    Music
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="gallerynav"
                    className="nav-link qwsmoothscroll"
                    href="#photos"
                  >
                    Gallery
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="bionav"
                    className="nav-link qwsmoothscroll"
                    href="#bio"
                  >
                    Biography
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="eventnav"
                    className="nav-link qwsmoothscroll"
                    href="#event"
                  >
                    Events
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="bookingnav"
                    className="nav-link qwsmoothscroll"
                    href="#booking"
                  >
                    Book DJX
                  </a>
                </li>
                {/* <li className="nav-item">
                    <a id="sponsorednav" className="nav-link qwsmoothscroll" href="#sponsored">
                      sponsored
                    </a>
                  </li> */}
                <li className="nav-item">
                  <a
                    id="videonav"
                    className="nav-link qwsmoothscroll"
                    href="#video"
                  >
                    DJX Videos
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
      </>
    );
  }
}

export default Home;
